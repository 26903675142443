'use strict';

import $ from 'jquery';
import d3 from 'd3';

export class NavigationController {
	constructor(nodes) {
		this.nodes = nodes;
		this.timing = 200;
		this.STATES = {
			CLOSED: 0,
			ABOUT: 1,
			COLOPHON: 2,
			CONTACT: 3,
		};
		this.navState = this.STATES.CLOSED;
	}

	clickAbout(_showHideLinked) {
		if (this.navState === this.STATES.CLOSED) {
			if(_showHideLinked) {
				this.hideLinked();
			}
			this.showAbout();
			this.navState = this.STATES.ABOUT;
		} else if (this.navState == this.STATES.ABOUT) {
			this.hideAbout();
			if(_showHideLinked) {
				this.showLinked();
			}
			this.navState = this.STATES.CLOSED;
		} else {
			this.hideColophon();
			this.hideContact();
			this.showAbout();
			this.navState = this.STATES.ABOUT;
		}
	}

	clickColophon(_showHideLinked) {
		if (this.navState === this.STATES.CLOSED) {
			if(_showHideLinked) {
				this.hideLinked();
			}
			this.showColophon();
			this.navState = this.STATES.COLOPHON;
		} else if (this.navState == this.STATES.COLOPHON) {
			this.hideColophon();
			if(_showHideLinked) {
				this.showLinked();
			}
			this.navState = this.STATES.CLOSED;
		} else {
			this.hideAbout();
			this.hideContact();
			this.showColophon();
			this.navState = this.STATES.COLOPHON;
		}
	}

	clickContact(_showHideLinked) {
		if (this.navState === this.STATES.CLOSED) {
			if(_showHideLinked) {
				this.hideLinked();
			}
			this.showContact();
			this.navState = this.STATES.CONTACT;
		} else if (this.navState == this.STATES.CONTACT) {
			this.hideContact();
			if(_showHideLinked) {
				this.showLinked();
			}
			this.navState = this.STATES.CLOSED;
		} else {
			this.hideColophon();
			this.hideAbout();
			this.showContact();
			this.navState = this.STATES.CONTACT;
		}
	}

	// hides any nav
	hideNav() {
		this.navState = this.STATES.CLOSED;
		this.hideContact();
		this.hideAbout();
		this.hideColophon();
	}

	// hide linked nodes
	hideLinked() {
		let _this = this;
		this.nodes.forEach(function (d) {
			if (d.linked) {
				d3.select(d.videoContainer).transition().duration(100).style('opacity', 0);
				d3.select(d.textElement).transition().duration(100).style('opacity', 0);
			}
		});
	}

	// show linked nodes
	showLinked() {
		let _this = this;
		this.nodes.forEach(function (d) {
			if (d.linked) {
				d3.select(d.videoContainer).transition().duration(100).style('opacity', 1);
				d3.select(d.textElement).transition().duration(100).style('opacity', 1);
			}
		});
	}

	showAbout() {
		$('body').addClass('read-stuff');
		$('#about').show().delay(this.timing).fadeTo(this.timing, 1);
		$('#about').show().delay(this.timing).fadeTo(this.timing, 1);
		$('#aboutButton').addClass('highlight');
	}

	hideAbout() {
		$('body').removeClass('read-stuff');
		$('#about').fadeTo(this.timing, 0).delay(this.timing).hide();
		$('#sub .about').removeClass('highlight');
	}

	showColophon() {
		$('body').addClass('read-stuff');
		$('#colophon').show().delay(this.timing).fadeTo(this.timing, 1);
		$('#sub .colophon').addClass('highlight');
	}

	hideColophon() {
		$('body').removeClass('read-stuff');
		$('#colophon').fadeTo(this.timing, 0).delay(this.timing).hide();
		$('#sub .colophon').removeClass('highlight');
	}

	showContact() {
		$('body').addClass('read-stuff');
		$('#contact').show().delay(this.timing).fadeTo(this.timing, 1);
		$('#sub .contact').addClass('highlight');
	}

	hideContact() {
		$('body').removeClass('read-stuff');
		$('#contact').fadeTo(this.timing, 0).delay(this.timing).hide();
		$('#sub .contact').removeClass('highlight');
	}
}
