'use strict';

// UTIL

export const getBrowser = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[0];
};

export const getBrowserVersion = () => {
	const N = navigator.appName;
	const ua = navigator.userAgent;
	let tem;
	let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
	if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
	M =  M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
	return M[1];
};

export function cEnum(obj) {
	const keysByValue = new Map();
	const EnumLookup = (value) => { keysByValue.get(value); };

	for (const key of Object.keys(obj)) {
		EnumLookup[key] = obj[key];
		keysByValue.set(EnumLookup[key], key);
	}

	// Return a function with all your enum properties attached.
	// Calling the function with the value will return the key.
	return Object.freeze(EnumLookup);
}

function clamp(value, outMin, outMax) {
	const min = Math.max(outMin, outMax);
	const max = Math.min(outMin, outMax);
	return Math.min(min, Math.max(max, value));
}

export function mapTo(value, inMin, inMax, outMin, outMax, bClamp = true) {
	const base = (value - inMin) / (inMax - inMin);
	const mapped = (outMax - outMin) * base + outMin;
	return bClamp ? clamp(mapped, outMin, outMax) : mapped;
}

export function Timer(callback, delay) {
	var timerId, start, remaining = delay;

	this.pause = function() {
		window.clearTimeout(timerId);
		remaining -= new Date() - start;
	};

	this.resume = function() {
		start = new Date();
		window.clearTimeout(timerId);
		timerId = window.setTimeout(callback, remaining);
	};

	this.resume();
};

// FRAGMENTS
export const Atmosphere = cEnum({
	None: 10,
	Frightening1: 0,
	Frightening2: 1,
	Frightening3: 2,
	Informative1: 3,
	Informative2: 4,
	Informative3: 5,
	Neutral1: 6,
	Neutral2: 7,
	Spectaculair1: 8,
	Spectaculair2: 9,
});

const categoryColors = {
	me: 'rgb(228,37,65)',
	bv: 'rgb(253,67,244)',
	bl: 'rgb(142,223,252)',
	mb: 'rgb(31,108,248)',
	bp: 'rgb(18,123,189)',
	ts: 'rgb(116,192,159)',
	dp: 'rgb(248,125,82)',
};

// export class Fragments {
// 	constructor(data) {
// 		Object.assign(this, data);
// 	}
// }

const preventDeadKids = (text) => {
	let kids = text.split(" ");
	kids[kids.length - 2] = `<span class='nobr'>${kids[kids.length - 2]}`;
	kids[kids.length - 1] = `${kids[kids.length - 1]}</span>`;
	kids = kids.join(' ');
	return kids;
}

const addProperties = (data) => {
	const nodes = data.map((elem, index) => {
		const out = elem;
		out.index = index;
		out.x = 200 * (index % 20);
		out.y = Math.round(index / 20.0) * 200;
		out.previewUrl = (elem.title.toLowerCase())
			.replace(/[^abcdefghijklmnopqrstuvwxyz0123456789 ]/g, '')
			.replace(/[ ]/g, '_');
		out.color = categoryColors[elem.category];
		out.text = preventDeadKids(out.text);

		// console.log(out.preview);
		return out;
	});
	const links = [];

	return {
		nodes,
		links,
	};
}

export class FragmentParser {
	constructor(filePath) {
		const request = new XMLHttpRequest();
		request.open('GET', filePath, false);
		request.send(null);
		const data = addProperties(JSON.parse(request.responseText));
		console.log('data:');
		console.log(data);
		Object.assign(this, data);
	}
}

// GENERAL
export const States = cEnum({
	None: 4,
	Over: 0,
	Macro: 1,
	Micro: 2,
	Transition: 3,
});

export class View {
	constructor(nodes) {
		this.nodes = nodes;
	}

	init() {}

	say(msg) {}

	getParams() {}
}

// D3
export class D3ForceParams {
	constructor(state, linkDistance, friction, gravity, maxCharge, standardCharge) {
		return {
			state,
			linkDistance,
			friction,
			gravity,
			maxCharge,
			standardCharge,
		};
	}
}
