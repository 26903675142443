'use strict';

import { View } from './classes';
import $ from 'jquery';

let params = {
			nodeWidth: 80,
			nodeHeight: 45,
			scaleup: 5,
		};

export class MacroView extends View {

	say(msg) {
		super.say(msg);
		console.log(`Macro says ${msg}`);
		$('.row').slideDown(0);
		$('#sub').slideUp(0);
		$('#utils #navigation .over').slideUp(0);
	}

	getParams() {
		return params;
	}

	setParams(_params) {
		params = _params;
	}

	transition() {
		// main layout
		$('.urButton').show();
		document.getElementById('bg_white').style.opacity = 0.0;
		document.getElementById('bg_red').style.opacity = 1.0;
		document.getElementById('bg_black').style.opacity = 0.0;

		$('#utils').removeClass('utils_collection');
		$('#utils').addClass('utils_macro');
		$('#utils').removeClass('utils_micro');

		$('.border').removeClass('border_collection');
		$('.border').addClass('border_macro');
		$('.border').removeClass('utils_micro');

		$('.text').removeClass('text_collection');
		$('.text').addClass('text_macro');
		$('.text').removeClass('text_micro');

		$('#navOverlays').removeClass('navOverlay_collection');
		$('#navOverlays').addClass('navOverlay_macro');
		$('#navOverlays').removeClass('navOverlay_micro');

		setTimeout(() => {
			$('#sub').slideUp('slow');
		},1000);

		// force layout
		$('.rect').css('cursor', 'pointer');

		$('.circle').css('opacity', 1.0);
		$('.link').css('stroke', 'white');
		$('.link').css('transition', 'opacity 5s');
		$('.link').css('opacity', 1);
		$('#cardinalPath').css('transition', 'opacity 5s');
		$('#cardinalPath').css('opacity', 1);
	}
}
