import $ from 'jquery';
import d3 from 'd3';
import { FragmentParser } from './classes';

export default class MobileController {
	constructor() {
		$('body').addClass('mobile');

		window.watchTrailer = this.watchTrailer.bind(this);
		window.pauseTrailer = this.pauseTrailer.bind(this);
		window.browseWebsite = this.browseWebsite.bind(this);
		window.mobileNavigate = this.mobileNavigate.bind(this);
		window.clickCollectionItem = this.clickCollectionItem.bind(this);
		window.onMobilePlayerStateChange = this.onMobilePlayerStateChange;

		this.content = $('.mobile-content');
	}

	mobileNavigate(el) {
		const $el = $(el);
		const link = $el.data('link');
		$('nav li').removeClass('active');
		$el.addClass('active');
		this[`navigate${link}`](el);
	}

	setContent(html) {
		this.content.css('opacity', 0);
		setTimeout(() => {
			$('body').scrollTop(0);
			this.content
				.html(html)
				.css('opacity', 1);
		}, 250);

		if ($('.explanation').is(':visible')) {
			$('.explanation').fadeOut();
		}
	}

	clickCollectionItem(el) {
		const $el = $(el);
		const data = $el.data('node');

		const oldItem = $('.collection-item .description:visible').parent();
		const oldDescriptionHeight = $('.collection-item .description:visible').height();

		$el.siblings('.expanded')
			.removeClass('expanded')
			.find('.description')
			.slideUp();

		$el.toggleClass('expanded')
			.find('.description')
			.slideToggle();

		const hasIframe = $el.find('.image-holder iframe').length !== 0;

		if (!hasIframe) {
			setTimeout(() => {
				const playerHTML = '<iframe type="text/html" width="100%" height="100%" ' +
								`src="http://www.youtube.com/embed/${data.youtube}` +
								'?autoplay=1&origin=http://themodularbody.com/" frameborder="0" allowfullscreen/>';
				const ytplayer = $(playerHTML);
				if (!hasIframe) {
					$el.find('.image-holder').append(ytplayer);
				}
			}, 1000);
		} else {
			$el.find('.image-holder iframe').remove();
		}

		if ($('.expanded').length > 0) {
			let offset = $('.expanded').offset().top;
			offset -= parseInt($('.nav-top').css('padding-top'), 10) * 2;
			const mq = window.matchMedia('(orientation: landscape)');
			if (!mq.matches) {
				offset -= $('.nav-top').height();
			}

			if (oldItem.index() < $el.index() && oldDescriptionHeight) {
				offset -= parseInt(oldDescriptionHeight, 10);
			}

			$('body').animate({
				scrollTop: offset,
			}, 500);
		}
	}

	createCollectionList() {
		const divs = [];
		this.fragments.nodes.forEach((node) => {
			const div = $('<div onclick="clickCollectionItem(this)" class="collection-item"/>');
			div.data('node', node);

			const imgHolder = $('<div class="image-holder"/>');
			imgHolder.append(`<img src="/assets/videos/png/${node.previewUrl}.png"/>`);
			// imgHolder.append('<div class="play-icon"/>');

			div.append(imgHolder);
			div.append(`<h2>${node.title}</h2>`);
			div.append(`<div class="description">${node.text}</div>`);
			divs.push(div);
		});

		// since there shouldn't be an order of watching the movies
		return d3.shuffle(divs);
	}

	navigateCollection(el) {
		const collection = this.createCollectionList();
		this.setContent(collection);
	}

	navigateContact(el) {
		const html = $('#contact').html();

		this.setContent(html);
	}

	navigateColophon(el) {
		const html = $('#colophon').html();

		this.setContent(html);
	}

	navigateAbout(el) {
		const html = $('#about').html();

		this.setContent(html);
	}

	isFullScreen() {
		return !!(document.fullScreen
			|| document.webkitIsFullScreen
			|| document.mozFullScreen
			|| document.msFullscreenElement
			|| document.fullscreenElement);
	}

	pauseTrailer() {
		$('#mobile-trailer')
			.removeClass('playing')
			.off('pause ended');

		if (document.exitFullscreen) document.exitFullscreen();
		else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
		else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
		else if (document.msExitFullscreen) document.msExitFullscreen();
	}

	browseWebsite() {
		$('body').addClass('browse');
		this.fragments = new FragmentParser('../assets/categories.json');
	}

	onMobilePlayerStateChange(event) {
		if (event.data === 2) {
			$('#mobile-trailer').removeClass('playing');
		} else if (event.data === 0) {
			$('#mobile-trailer').removeClass('playing');
			window.browseWebsite();
		}
	}

	watchTrailer() {
		if (window.player.getPlayerState() === 2) {
			window.player.seekTo(0);
			window.player.playVideo();
		}

		$('#mobile-trailer').addClass('playing');
			// .bind('pause', this.pauseTrailer);

		// const videoContainer = $('video#mobile-trailer').get(0);
		// videoContainer.onended = this.browseWebsite;
		// videoContainer.load();
		// videoContainer.play();

		// if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
		// else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
		// else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
		// else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
	}
}
