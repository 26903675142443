'use strict';

// ::::::::::::::::::::::::::::::::::::::::::::: imports
import MobileDetect from 'mobile-detect';
import { ViewController } from './viewController';
import { Timer, getBrowserVersion } from './classes';
import MobileController from './mobileController';
import attachFastClick from 'fastclick';
import $ from 'jquery';
import { getUrlParameter } from './forceLayout';

// ::::::::::::::::::::::::::::::::::::::::::::: variables
let width = window.innerWidth;
let height = window.innerHeight;
const offline = false;

const viewController = new ViewController();
let player;
let playerTimer = false;
let shareIds = [];

let is_safari = false;

// ::::::::::::::::::::::::::::::::::::::::::::: functions

function loadPlayerJS() {
	let tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';
	let firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function setupViewController(shareIds) {
	width = window.innerWidth;
	height = window.innerHeight;
	viewController.setup(shareIds, is_safari);
	viewController.setViewport(width, height);
}

function init(shareIds) {
	$('body').addClass('desktop');

	$('#audio source').attr('src', 'assets/audio/baseMix.mp3');
	document.getElementById('audio').load();

	viewController.setOffline(offline);
	if (!offline) {
		loadPlayerJS();
	} else {
		viewController.setup(shareIds, is_safari);
		viewController.setViewport(width, height);
		offlineTrailer();
	}

	// ::::::::::::::::::::::::::::::::::::::::::::: callers
	$(document).mousemove((event) => {
		if(viewController.isSetup){
			viewController.mousemove(event);
		}
	});

	$('#svg').mouseup((event) => {
		if(viewController.isSetup){
			viewController.mouseup(event);
		}
	});

	$(document).on('mousewheel DOMMouseScroll MozMousePixelScroll', function (event) {
		if(viewController.isSetup){
			viewController.mousescroll(event);
		}
	});

	$(window).resize(() => {
		width = window.innerWidth;
		height = window.innerHeight;
		if(viewController.isSetup){
			viewController.setViewport(width, height);
		}
	});
}

const closeOfflineTrailer = () => {
	$('#trailer').animate({
		top: height / 2,
		left: width / 2,
		height: 1,
		width: 1,
	}, 600, () => {
		$('#trailer').css('display', 'none');
		// Animation complete.
		$('#audio source').attr('src', 'assets/audio/baseMix.mp3');
		document.getElementById('audio').load();
		document.getElementById('audio').play();
		$('#trailer').remove();
		viewController.start();
	});
	$('#skip').remove();
};

const offlineTrailer = () => {
	const trailer = document.getElementById('trailer');
	trailer.innerHTML = '<video id="trailer_video" autoplay height="100%" width="100%"><source id="trailer_video_mp4_src" src="assets/videos/offMp4/trailer.mp4" type="video/mp4"></video>';

	const trailerVideo = document.getElementById('trailer_video');

	trailerVideo.addEventListener('timeupdate', (e) => {
		if (e.srcElement.currentTime > 39.5) {
			closeOfflineTrailer();
		}
	}, false);

	trailerVideo.addEventListener('ended', closeOfflineTrailer, false);

	$('#skip').on('click', () => {
		closeOfflineTrailer();
	});
};

const closeTrailer = () => {
	$('#trailer').animate({
		top: height / 2,
		left: width / 2,
		height: 1,
		width: 1,
	}, 600, () => {
		$('#trailer').css('display', 'none');
		// Animation complete.

		// if player was mounted...
		if (player.stopVideo) {
			player.stopVideo();
		}

		document.getElementById('audio').play();
		$('#trailer').remove();

		if (viewController.isSetup && window.YT) {
			viewController.start();
		}
	});
	$('#skip').remove();
};

const onPlayerStateChange = (event) => {
	if (event.data === 1) {
		if (playerTimer !== false) {
			playerTimer.resume();
		} else {
			playerTimer = new Timer(() => {
				closeTrailer();
			}, 40000);
		}
	} else if (event.data === 2 || event.data === 3) {
		if (playerTimer !== false) {
			playerTimer.pause();
		}
	}
};

const setupYTPlayer = () => {
	if ($('body').hasClass('desktop')) {
		const autoplay = (shareIds.length === 0) && !is_safari ? 1 : 0;
		player = new YT.Player('trailer', {
			height: '100%',
			width: '100%',
			videoId: '0ODlMMhVxYc',
			playerVars: { autoplay: autoplay, controls: 0, showinfo: 0, loop: 0, rel: 0 },
			events: {
				onStateChange: onPlayerStateChange,
			},
			origin: 'https://www.youtube.com',
		});

		// this should only happen when user got here via share link
		if (autoplay === 0 && !is_safari) {
			clearTimeout(playerTimer);
			closeTrailer();
		}
	} else if ($('body').hasClass('mobile')) {
		window.player = new YT.Player('mobile-trailer-video', {
			// height: '100%',
			width: '100%',
			videoId: '0ODlMMhVxYc',
			playerVars: { autoplay: 0, controls: 0, showinfo: 0, loop: 0, rel: 0 },
			events: {
				onStateChange: window.onMobilePlayerStateChange,
			},
		});
	}
};

window.onYouTubeIframeAPIReady = function () {
	console.log('Player API Ready');
	setupYTPlayer();
	if ($('body').hasClass('desktop')) {
		viewController.setupYTPlayer();
		setupViewController(shareIds);
	}
};

function detectIE() {
	if (navigator.userAgent.indexOf('MSIE') !== -1
	|| navigator.appVersion.indexOf('Trident/') > 0) {
		return true;
	}

	return false;
}

// ::::::::::::::::::::::::::::::::::::::::::::: initialization
const shareString = getUrlParameter('share');
if (shareString) shareIds = shareString.split(',').map(Number);

const md = new MobileDetect(window.navigator.userAgent);
if (md.mobile() !== null) {
	const app = new MobileController();
	attachFastClick(document.body);
	loadPlayerJS();
	$('#message').hide();
} else {
	let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
	let is_explorer = detectIE();
	let is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
	is_safari = navigator.userAgent.indexOf('Safari') > -1;
	let is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1;
	if ((is_chrome) && (is_safari)) {is_safari = false;}

	if ((is_chrome) && (is_opera)) {is_chrome = false;}

	if (!is_explorer && !is_safari) {
		$('#message').hide();
		init(shareIds);
	} else {
		$('#message').show();
	}
	if (is_safari) {
		let simpleBrowserVersion = getBrowserVersion().match(/^[0-9]*.[0-9]*/); // just look at base version + the first decimal... otherwise comparison is confused
		if (simpleBrowserVersion >= 9.1) {
			init(shareIds);
			$('#message').show();
			$('#message').html('<h3>Please wait..</h3><p>Video fragments are being loaded...<br>this can take a minute (depending on your internet connection).</p>');
			$('#skip').html('loading ...');
			window.onload = function () {
				if (shareIds.length > 0) {
					$('#message').hide();
					clearTimeout(playerTimer);
					closeTrailer();
				} else {
					$('#message').hide();
					player.playVideo();
					$('#skip').html('skip');
					$('#skip').on('click', () => {
						clearTimeout(playerTimer);
						closeTrailer();
					});
				}
			};
		} else {
			$('#message').show();
			$('#message').html(`<h3>Incompatible browser..</h3><p>Your browser version is Safari ${getBrowserVersion()}<br>Please update to the newest Safari version (at least 9.1) to view this website.</p> \
				<br><br><p>In the meantime you can use <a href="https://www.google.com/chrome/browser/desktop/">Chrome</a> to view this website.</p>`);
		}
	} else {
		$('#skip').on('click', () => {
			clearTimeout(playerTimer);
			closeTrailer();
		});
	}

}

/*********************************************** help

// ::::::::::::::::::::::::::::::::::::::::::::: app.js

	app.js is the main class.

	it collects user and window information,
	and fires up viewController

// ::::::::::::::::::::::::::::::::::::::::::::: viewController.js

	here, force and views are controlled.

// ::::::::::::::::::::::::::::::::::::::::::::: more

	there is more, but i haVE NO TIME TO EXPLAIN THAT RIGHT NOW.

 ************************************************/
