'use strict';

import { FragmentParser, States, D3ForceParams } from './classes';
import { OverView } from './overview';
import { MacroView } from './macroview';
import { MicroView } from './microview';
import { ForceLayout } from './forceLayout';
import { NavigationController } from './navigationController';
import $ from 'jquery';

const views = [new OverView(), new MacroView(), new MicroView()];
const fragments = new FragmentParser('../assets/categories.json');
let state = {
	last: States.None,
	current: States.Macro,
	next: States.None,
};
let navController;
let force;
let is_safari = false;

let offline = false;
let muteButtonActivated = false;
let autoplayActivated = false;

/*
 *	the force params are:
 *			state,
 *			linkDistance,
 *			friction,
 *			gravity,
 *			maxCharge,
 *			standardCharge,
 */

const forceParams = [
	new D3ForceParams(
		States.Over,	// state
		0,				// linkDistance
		0,				// friction
		0,				// gravity
		0,				// maxCharge
		0),				// standardCharge
	new D3ForceParams(
		States.Macro,	// state
		100,			// linkDistance
		0.6,			// friction
		0.14,				// gravity
		-30000,				// maxCharge
		-1000),				// standardCharge
	new D3ForceParams(
		States.Micro,	// state
		100,			// linkDistance
		0.6,			// friction
		0.14,				// gravity
		-30000,				// maxCharge
		-2000),				// standardCharge
];

function nextState() {
	state = {
		last: state.current,
		current: state.next,
		next: States.None,
	};
}

function transitionState(next) {
	$('body').removeClass('read-stuff');
	if (state.current !== next && state.next === States.None) {
		const millis = 1000;
		state.next = next;
		views[state.next].transition();

		// console.log(`started transition from ${state.current} to ${state.next}`);
		force.setForce(forceParams[state.next], millis);

		setTimeout(() => {
			console.log(`finished transition from ${state.current} to ${state.next}`);
			nextState();
		}, millis);
	}
}

function setupVideos() {
	for (let i = 0; i < fragments.nodes.length; i++) {
		const fragment = fragments.nodes[i];

		$('#videos').append('<div id="' + fragment.id + '_video_container" class="video_container" style="width:' + views[state.current].getParams().nodeWidth + 'px; height:' + views[state.current].getParams().nodeHeight + '">' +
				'<img class="thumb ' + fragment.id + '_preview" id ="' + fragment.id + '_preview" src="assets/videos/png/' + fragment.previewUrl + '.png">' +
				'<video class="video" id="' + fragment.id + '_video" loop>' +
				'<source id="' + fragment.id + '_video_webm_src" src="assets/videos/webm/' + fragment.previewUrl + '.webm" type="video/webm">' +
				'<source id="' + fragment.id + '_video_mp4_src" src="assets/videos/mp4/' + fragment.previewUrl + '.mp4" type="video/mp4">' +
				'</video>' +
				'<div id="' + fragment.id + '_collectedText" class="collectedText"><p>collected</p></div>' +
			'</div>');
		$('#body')//('#' + fragment.id + '_video_container')
			.append(`<div id="${fragment.id}_text" class="text"><div class="category">${fragment.description}</div><h3 class="title">${fragment.title}</h3><p><span class="info">${fragment.text}</span></p></div>`);

		const vid = document.getElementById(`${fragment.id}_video`);
		const thumb = document.getElementById(`${fragment.id}_preview`);
		const text = document.getElementById(`${fragment.id}_text`);
		fragments.nodes[i].videoContainer = document.getElementById(`${fragment.id}_video_container`);
		fragments.nodes[i].preview = thumb;
		fragments.nodes[i].focus = false;
		fragments.nodes[i].hasVideo = false;
		fragments.nodes[i].textElement = text;
		thumb.style.borderColor = fragments.nodes[i].color;
		thumb.onload = () => {
			$('#message').append(`<p style="font-size: 10px">... ${fragment.title}</p>`);
		};
		vid.onload = () => {
			$('#message').append(`<p style="font-size: 10px">... ${fragment.title}</p>`);
		};

		vid.oncanplay = () => {
			$(thumb).remove(); // thumbnail will never be used again, why not just remove it?
			$(vid).show();
			fragments.nodes[i].preview = vid;
			fragments.nodes[i].hasVideo = true;
			$(vid).attr('id', `${fragment.id}_preview`);
			$(vid).addClass(`${fragment.id}_preview`);
			$(vid).addClass('preview');
			vid.style.borderColor = fragments.nodes[i].color;
			$(fragments.nodes[i].videoContainer).attr('data-hasVideo', 'true');
		};
	}
}

const setMuteAudio = (mute) => {
	const audio = document.getElementById('audio');
	if (!mute) {
		$('#mute').removeClass('urHighlight');
		audio.muted = false;
		$(audio).stop().animate({
			volume: 1.0,
		}, {
			duration: 1000,
		});
	} else {
		$('#mute').addClass('urHighlight');
		$(audio).stop().animate({
			volume: 0.0,
		}, {
			duration: 500,
			complete: () => {
				audio.muted = true;
			},
		});
	}
};

export class ViewController {
	constructor() {
		this.isSetup = false;
		this.setupYT = false;
	}

	setup(shareIds, _is_safari) {
		is_safari = _is_safari;
		setupVideos();
		navController = new NavigationController(fragments.nodes);
		force = new ForceLayout(shareIds, is_safari);
		force.setForce(forceParams[state.current]);
		force.setup(fragments, [views[States.Over].getParams(), views[States.Macro].getParams(), views[States.Micro].getParams()]);
		if (!this.setupYT && !offline && window.YT) {
			this.setupYTPlayer();
		} else {
			force.setOffline(offline);
		}

		transitionState(States.Macro);
		views[state.current].say('hi');

		// set up button interactions
		$('#navigation p').each((index, elem) => {
			$(elem).click((event) => {
				force.setDeactivateAction(false);
				transitionState(index+1);
				navController.hideNav();
				if (state.current === States.Over) {
					navController.showLinked();
				}
				event.stopPropagation();
			});
		});

		$('#title').on('click', () => {
			if (state.current === States.Over) {
			} else if (state.current === States.Macro) {
				$('#sub').slideToggle('slow');
			} else if (state.current === States.Micro){
				if ($('#sub').is(':visible')) {
					$('#sub').slideUp('slow');
					$('.rowActive').slideUp('slow');
				} else {
					$('#sub').slideDown('slow');
					$('.rowActive').slideDown('slow');
				}
			}
		});

		$('#aboutButton').click((event) => {
			navController.clickAbout(state.current === States.Over);
			force.setDeactivateAction(navController.navState !== navController.STATES.CLOSED);
			if (navController.navState !== navController.STATES.CLOSED) {
				force.unfocus();
			};
		});

		$('#colophonButton').click((event) => {
			navController.clickColophon(state.current === States.Over);
			force.setDeactivateAction(navController.navState !== navController.STATES.CLOSED);
			if (navController.navState !== navController.STATES.CLOSED) {
				force.unfocus();
			};
		});

		$('#contactButton').click((event) => {
			navController.clickContact(state.current === States.Over);
			force.setDeactivateAction(navController.navState !== navController.STATES.CLOSED);
			if (navController.navState !== navController.STATES.CLOSED) {
				force.unfocus();
			};
		});

		$('#mute').click((event) => {
			if (!muteButtonActivated) {
				muteButtonActivated = true;
				$('#mute').addClass('highlight');
				setMuteAudio(true);
			} else {
				muteButtonActivated = false;
				$('#mute').removeClass('highlight');
				setMuteAudio(false);
			}
		});
		$('#autoplay').click((event) => {
			autoplayActivated = !autoplayActivated;
			if (autoplayActivated) {
				$('#autoplay').addClass('urHighlight');
			} else {
				$('#autoplay').removeClass('urHighlight');
			}
			force.setAutoplay(autoplayActivated);
		});
		$('#refresh').click((event) => {
			this.reset();
		});

		$(window).focus(() => {
			if (!muteButtonActivated) {
				setMuteAudio(false);
			}
		})
		.blur(() => {
			setMuteAudio(true);
		});

		this.blockScrolling = false;
		views[state.current].transition();
		this.isSetup = true;
	}

	reset() {
		force.reset();
	}

	setupYTPlayer() {
		if (force && !this.setupYT) {
			this.setupYT = true;
			force.setupYT();
		}
	}

	setOffline(_offline) {
		offline = _offline;
	}

	setViewport(width, height) {
		if (height < 940) {
			$('.colr').css('height', '120px');
			$('.coll').css('height', '120px');
			$('.rotatedText p').css('transform', 'rotate(-90deg) translate(-50px, 0px)');
		}

		force.setViewport(width, height);
	}

	mousemove(event) {
		if (this.isSetup && navController.navState === navController.STATES.CLOSED) {
			force.mousemove(event);
		}
	}

	mousescroll(e) {
		// console.log(e);
		let delta = e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -e.detail * 24;
		// console.log(delta);
		force.mousescroll(delta);
	}

	mouseup(event) {
		if (this.isSetup) {
			force.mouseup(event);
		}
	}

	start() {
		force.start();
	}
}
