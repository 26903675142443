'use strict';

import { View } from './classes';
import $ from 'jquery';

let params = {
			nodeWidth: 160 * 2,
			nodeHeight: 90 * 2,
			scaleup: 4,
		};

export class OverView extends View {
	say(msg) {
		super.say(msg);
		console.log(`Over says ${msg}`);
	}

	getParams() {
		return params;
	}

	setParams(_params) {
		params = _params;
	}

	transition() {
		// main layout
		$('.urButton').hide();

		document.getElementById('bg_white').style.opacity = 1.0;
		document.getElementById('bg_red').style.opacity = 0.0;
		document.getElementById('bg_black').style.opacity = 0.0;

		$('#utils').addClass('utils_collection');
		$('#utils').removeClass('utils_macro');
		$('#utils').removeClass('utils_micro');

		$('.border').addClass('border_collection');
		$('.border').removeClass('border_macro');
		$('.border').removeClass('utils_micro');

		$('.text').addClass('text_collection');
		$('.text').removeClass('text_macro');
		$('.text').removeClass('text_micro');
		
		$('#navOverlays').addClass('navOverlay_collection');
		$('#navOverlays').removeClass('navOverlay_macro');
		$('#navOverlays').removeClass('navOverlay_micro');

		setTimeout(() => {
			$('.row').slideDown("slow");
			$('#sub').slideDown("slow");
		},1000);


		$('.rect').css('pointer-events', 'none');
		$('.rect').css('cursor', 'default');
		$('.nodeElem').css('opacity', 0);

		$('.link').css('transition', 'opacity 5s');
		$('.link').css('opacity', 0);

		$('#cardinalPath').css('transition', 'opacity 5s');
		$('#cardinalPath').css('opacity', 0);
	}
}
