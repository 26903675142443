'use strict';

import { View } from './classes';
import $ from 'jquery';

let params = {
			nodeWidth: 80,
			nodeHeight: 45,
			scaleup: 4,
		};

export class MicroView extends View {
	say(msg) {
		super.say(msg);
		console.log(`Micro says ${msg}`);
	}

	getParams() {
		return params;
	}

	setParams(_params) {
		params = _params;
	}
	
	transition() {
		// main layout
		$('.urButton').show();
		document.getElementById('bg_white').style.opacity = 0.0;
		document.getElementById('bg_red').style.opacity = 0.0;
		document.getElementById('bg_black').style.opacity = 1.0;

		$('#utils').removeClass('utils_collection');
		$('#utils').removeClass('utils_macro');
		$('#utils').addClass('utils_micro');

		$('#navOverlays').removeClass('navOverlay_collection');
		$('#navOverlays').removeClass('navOverlay_macro');
		$('#navOverlays').addClass('navOverlay_micro');
		
		setTimeout(() => {
			$('#sub').slideUp("slow");
		}, 1000);

		setTimeout(() => {
			$('.row').slideUp("slow");
		}, 1000);
		
		// $('#utils').on("click", () => {
		// 	$('#upper').slideDown("slow");
		// });

		// force layout
		$('.circle').css('opacity', 0.0);
		$('.circle').css('transition', 'opacity 2.6s');
		$('.collectedText').css('display', 'none');
		$('#cardinalPath').css('transition', 'opacity 0s');
	}
}
