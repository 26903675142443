'use strict';

import d3 from 'd3';
import $ from 'jquery';
import {
	States,
	mapTo,
} from './classes';

let is_safari = false;

const params = {
	last: -2,
	current: -1,
	next: -1,
};

let autoplay = false;
let offline = false;
let deactivateAction = false;

const audio = document.getElementById('audio');
const audioFadeInTime = 2000;
const audioFadeOutTime = 5000;

const svg = d3.select('body').append('svg').attr('id', 'svg');

const force = d3.layout.force();

const fragments = {};

const mouse = {
	x: 1,
	y: 1,
};

let mouseTimer = { val: 1.0 };
let overTimer;

let scroll = 0;
let maxScroll = 0;
let scrollParams = {};

let youtube = false;
let focus = false;
let player;

let dragging = -1;
let hitNode = 0;

let width = $(window).width();
let height = $(window).height();

let link;
let node;

let lastNode = -1;

const pseudobrownian = [
	[],
	[],
]; // uuuh ugly javascript multidimensional array ey

const walkParams = {
	threshold: 0.4,
	speed: 0.4,
	fidgety: 0.91,
};

const getDistance = (a, b) => {
	const x = Math.abs(a.x - b.x);
	const y = Math.abs(a.y - b.y);
	const xy = Math.abs(x - y) * 0.5;
	return x + y + xy;
};

// Lets brownian around, shall we?
const walk = (intensity, position, i, xy) => {
	const pseudoSwitch = Math.random() > walkParams.fidgety ? -1 : 1;
	if (pseudobrownian[xy][i] !== 1 && pseudobrownian[xy][i] !== -1) {
		pseudobrownian[xy][i] = 1;
	}

	pseudobrownian[xy][i] *= pseudoSwitch;
	return position + intensity * pseudobrownian[xy][i];
};

// Moves a fragment
const moveFragment = (opacity, position, i, xy) => {
	const intensity = Math.max(0, mapTo(1.0 - opacity, walkParams.threshold, 1, 0, walkParams.speed));
	return walk(intensity, position, i, xy);
};

let shared = false;

const refreshLinks = () => {
	const points = [];

	fragments.links.forEach((d, i) => {
		points.push({
			x: fragments.nodes[d.source.index].x,
			y: fragments.nodes[d.source.index].y,
		});

		if (i === fragments.links.length - 1) { // && params.current.state !== States.Micro) {
			points.push({
				x: fragments.nodes[d.target.index].x,
				y: fragments.nodes[d.target.index].y,
			});
		}
	});

	const cardinalFunction = d3.svg.line()
							.x((d) => { return d.x; })
							.y((d) => { return d.y; })
							.interpolate('cardinal');

	let opacity;

	if (params.current.state === States.Micro) {
		opacity = mouseTimer.val;
	} else if (params.current.state === States.Macro) {
		opacity = 1.0;
	} else if (params.current.state === States.Over) {
		opacity = 0.0;
	}
	$('#cardinalPath').remove();
	svg.append('path')
		.attr('id', 'cardinalPath')
		.attr('class', 'link')
		.attr('d', cardinalFunction(points))
		.attr('stroke', 'white')
		// .attr('stroke-opacity', opacity)
		.attr('stroke-width', 2)
		.attr('fill', 'none');
	document.getElementById('cardinalPath').style.opacity = opacity;
};

export const getUrlParameter = (sParam) => {
	const sPageURL = decodeURIComponent(window.location.search.substring(1));
	const sURLVariables = sPageURL.split('&');
	let sParameterName;
	let i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : sParameterName[1];
		}
	}

	return undefined;
};

const createScrollParams = () => {
	const _scrollParams = {};
	_scrollParams.padding = 50;
	_scrollParams.ratio = (height - _scrollParams.padding) / height;
	_scrollParams.height = height - (_scrollParams.padding * 2);
	_scrollParams.barHeight = _scrollParams.ratio * height * (height / (height + Math.abs(maxScroll)));
	return _scrollParams;
};

const scrollBarScrolling = (e) => {
	const maxBarPos = (height - scrollParams.padding) - scrollParams.barHeight;
	const newBarPos =
			Math.min(
				Math.max(
					scrollParams.padding,
					e.pageY - scrollParams.mouseOffset),
				maxBarPos
			);
	scroll = mapTo(newBarPos, scrollParams.padding, maxBarPos, 0, maxScroll);
	$('#scrollBarBar').offset({
		top: newBarPos,
	}).on('mouseup', () => {
		$(this).removeClass('draggedScroll');
		$(window).off('mousemove', scrollBarScrolling);
	});
};

const setupScrollBar = () => {
	document.getElementById('scrollBar').style.display = 'block';
	scrollParams = createScrollParams();
	$('#scrollBarBar').on('mousedown', (e) => {
		scrollParams.mouseOffset = (e.pageY - document.getElementById('scrollBarBar').offsetTop) - scrollParams.padding;
		$(this).addClass('draggedScroll');
		$(window).on('mousemove', e, scrollBarScrolling);
		e.preventDefault();
	});
	$(window).on('mouseup', () => {
		$(window).off('mousemove', scrollBarScrolling);
		$('.draggedScroll').removeClass('draggedScroll');
	});
	document.getElementById('scrollBar').style.display = 'none';
};

const updateScrollBar = () => {
	document.getElementById('scrollBar').style.top = `${scrollParams.padding}px`;
	document.getElementById('scrollBar').style.height = `${scrollParams.height}px`;
	document.getElementById('scrollBarBar').style.height = `${scrollParams.barHeight}px`;
	scrollParams.barHeight = scrollParams.ratio * height * (height / (height + Math.abs(maxScroll)));
	document.getElementById('scrollBarBar').style.top = `${mapTo(scroll, 0, maxScroll, 0, scrollParams.height - scrollParams.barHeight)}px`;
};

const ticks = [];
// tick States.Over
ticks.push(() => {
	node
		.each((d, i) => {
			// $(`#${d.id}_video_container`).offset({ left: d.x + d.w * -0.5, top: d.y + d.h * -0.5 });
			fragments.nodes[i].videoContainer.style.top = `${d.y + scroll + d.h * -0.5}px`;
			fragments.nodes[i].videoContainer.style.left = `${d.x + d.w * -0.5}px`;
			fragments.nodes[i].textElement.style.top = `${(d.h * 0.5) + d.y + scroll}px`;
			fragments.nodes[i].textElement.style.left = `${d.x - (d.w * 0.5)}px`;
		});
	refreshLinks();
});
// tick States.Macro
ticks.push(() => {
	if (focus === false) {
		node
			.attr('transform', (d, i) => {
				const videoContainer = fragments.nodes[i].videoContainer;
				const rect = fragments.nodes[i].rect;
				const mappedDistance = mapTo(getDistance(mouse, d), 0, 400, 1, 0, true);
				const preview = fragments.nodes[i].preview;

				const thresh = 0.5;
				if (d.hasVideo && !is_safari) {
					const video = preview;
					if (mappedDistance > thresh) {
						if (video.paused) {
							video.play();
						}

						video.volume = mappedDistance < 0.9 ? mappedDistance * 0.5 : 0.5;

					} else if (mappedDistance <= thresh && !video.paused) {
						video.pause();
						video.volume = 0;
					}
				}

				fragments.nodes[i].h =
					Math.floor(
						fragments.sizes[States.Macro].nodeHeight
							* (d.narrative * 0.125 + 0.5) * (1.0 - mappedDistance)
						+ ((fragments.sizes[States.Macro].scaleup
							* fragments.sizes[States.Macro].nodeHeight) * mappedDistance)
						);
				fragments.nodes[i].w = fragments.nodes[i].h * 16.0 / 9.0; // all videos will have same ratio

				const videoContainerStyle = videoContainer.style;
				videoContainerStyle.zIndex = 10 + Math.floor(mappedDistance * 90);
				videoContainerStyle.width = `${fragments.nodes[i].w}px`;
				videoContainerStyle.height = `${fragments.nodes[i].h}px`;

				fragments.nodes[i].zIndex = 10 + Math.floor(mappedDistance * 90);
				const newPosX = moveFragment(mappedDistance, d.x, i, 0);
				const newPosY = moveFragment(mappedDistance, d.y, i, 1);

				fragments.nodes[i].x = Math.max(fragments.sizes[States.Macro].nodeWidth * 3,
					Math.min(width - fragments.sizes[States.Macro].nodeWidth, newPosX));
				fragments.nodes[i].y = Math.max(fragments.sizes[States.Macro].nodeHeight * 2,
					Math.min(height - fragments.sizes[States.Macro].nodeHeight, newPosY));

				rect.setAttribute('width', `${fragments.nodes[i].w}px`);
				rect.setAttribute('height', `${fragments.nodes[i].h}px`);
				rect.setAttribute('x', `${d.w * -0.5}px`);
				rect.setAttribute('y', `${d.h * -0.5}px`);

				// const rectStyle = rect.style;
				// rectStyle.width = `${fragments.nodes[i].w}px`;
				// rectStyle.height = `${fragments.nodes[i].h}px`;
				// rectStyle.x = `${d.w * -0.5}px`;
				// rectStyle.y = `${d.h * -0.5}px`;

				if (d.linked) {
					preview.style.opacity = 1;
					if (mappedDistance > 0.6) {
						document.getElementById(`${d.id}_collectedText`).style.display = 'flex';
					} else {
						document.getElementById(`${d.id}_collectedText`).style.display = 'none';
					}
				} else {
					preview.style.opacity = mappedDistance * 0.8 + 0.2;
				}

				videoContainerStyle.left = `${Math.floor(fragments.nodes[i].x + fragments.nodes[i].w * -0.5)}px`;
				videoContainerStyle.top = `${Math.floor(fragments.nodes[i].y + fragments.nodes[i].h * -0.5)}px`;

				if (fragments.nodes[i].textElement.style.opacity > 0.99) {
					fragments.nodes[i].textElement.style.opacity = 0.99;
					fragments.nodes[i].textElement.style.transition = 'opacity 0.5s';
					fragments.nodes[i].textElement.style.opacity = 0.0;
				}

				return `translate( ${d.x},${d.y} )`;
			});
	} else {
		node.attr('transform', (d, i) => {
			if (d.focus === false) {
				const videoContainer = fragments.nodes[i].videoContainer;
				const preview = fragments.nodes[i].preview;
				const mappedDistance = mouseTimer.val * mapTo(getDistance(mouse, d), 0, 400, 1, 0, true);

				const thresh = 0.5;
				if (d.hasVideo && !is_safari) {
					// const video = videoContainer.getElementById(`${d.id}_preview`);
					const video = preview;
					if (mappedDistance > thresh) {
						if (video.paused) {
							video.play();
						}

						video.volume = (mappedDistance < 0.9 ? mappedDistance * 0.5 : 0.5) * mouseTimer.val;

						// $(`#${d.id}_video`).get(0).playbackRate = mappedDistance;
					} else if (mappedDistance <= thresh && !video.paused) {
						video.pause();
						video.volume = 0;//mappedDistance;
						// $(`#${d.id}_video`).get(0).playbackRate = mappedDistance;
					}
				}  else if (d.hasVideo && is_safari) {
					const video = preview;
					if (!video.paused) {
						video.volume = 0.5 * mouseTimer.val;
					}
				}

				if (d.linked) {
					preview.style.opacity = 1;
				} else {
					preview.style.opacity = mappedDistance * (1.0 - d.fo) + d.fo;

					// fragments.nodes[i].y = fragments.nodes[i].fy;
					fragments.nodes[i].h = //d.fh;
						Math.floor(
							d.fh * (1.0 - mappedDistance)
							+ ((d.fh * 1.1 + (fragments.sizes[States.Macro].nodeHeight * fragments.sizes[States.Macro].scaleup * 0.3)) * mappedDistance)
							);
					fragments.nodes[i].w = fragments.nodes[i].h * 16.0 / 9.0; // all videos will have same ratio
				}

				// if (
				// 	d.x <= ((width * 0.5) + youtube.w * 0.5) && d.x >= ((width * 0.5) - youtube.w * 0.5)
				// 	&& d.y <= ((height * 0.5) + youtube.h * 0.5) && d.y >= ((height * 0.5)- youtube.h * 0.5)) {
				// 	fragments.nodes[d.index].x = 20;
				// 	fragments.nodes[d.index].y = 20;
				// }

				fragments.nodes[i].zIndex = 10 + Math.floor(mappedDistance * 90);
				const videoContainerStyle = videoContainer.style;
				videoContainerStyle.zIndex = fragments.nodes[i].zIndex;
				videoContainerStyle.width = `${fragments.nodes[i].w}px`;
				videoContainerStyle.height = `${fragments.nodes[i].h}px`;
				videoContainerStyle.left = `${Math.floor(fragments.nodes[i].x + fragments.nodes[i].w * -0.5)}px`;
				videoContainerStyle.top = `${Math.floor(fragments.nodes[i].y + fragments.nodes[i].h * -0.5)}px`;

				const rect = document.getElementById(`${d.id}_rect`);
				rect.setAttribute('width', `${fragments.nodes[i].w}px`);
				rect.setAttribute('height', `${fragments.nodes[i].h}px`);
				rect.setAttribute('x', `${d.w * -0.5}px`);
				rect.setAttribute('y', `${d.h * -0.5}px`);

				// const rectStyle = document.getElementById(`${d.id}_rect`).style;
				// rectStyle.width = `${fragments.nodes[i].w}px`;
				// rectStyle.height = `${fragments.nodes[i].h}px`;
				// rectStyle.x = `${d.w * -0.5}px`;
				// rectStyle.y = `${d.h * -0.5}px`;

				// console.log(d);
				return `translate( ${d.x},${d.y} )`;
			} else {
				fragments.nodes[i].textElement.style.top = `${(height - (fragments.sizes[States.Macro].nodeHeight * 1.5 + fragments.sizes[States.Macro].nodeHeight * 1.5 * fragments.sizes[States.Macro].scaleup)) * 0.5}px`;
				fragments.nodes[i].textElement.style.left = '';
				fragments.nodes[i].textElement.style.right = `${40}px`;

				// const finalScale = {
				// 	w: fragments.sizes[States.Macro].nodeWidth + fragments.sizes[States.Macro].nodeWidth * fragments.sizes[States.Macro].scaleup,
				// 	h: fragments.sizes[States.Macro].nodeHeight + fragments.sizes[States.Macro].nodeHeight * fragments.sizes[States.Macro].scaleup,
				// };
				// fragments.nodes[i].x = (width / 2);
				// fragments.nodes[i].y = (height / 2);
				// fragments.nodes[i].w = (finalScale.w);
				// fragments.nodes[i].h = (finalScale.h);

				// const videoContainer = fragments.nodes[i].videoContainer;
				// videoContainer.style.left = `${Math.floor(fragments.nodes[i].x + fragments.nodes[i].w * -0.5)}px`;
				// videoContainer.style.top = `${Math.floor(fragments.nodes[i].y + fragments.nodes[i].h * -0.5)}px`;
				// videoContainer.style.width = `${fragments.nodes[i].w}px`;
				// videoContainer.style.height = `${fragments.nodes[i].h}px`;
				return `translate( ${d.x},${d.y} )`;
			}
		});
	}

	refreshLinks();

	svg.selectAll('.node').sort((a, b) => { // select the parent and sort the path's
		if (a.zIndex > b.zIndex) {
			return 1; // a is not the hovered element, send 'a' to the back
		}

		return -1; // a is the hovered element, bring 'a' to the front
	});
});
// tick States.Micro
ticks.push(() => {
	node.attr('transform', (d, i) => {
		const videoContainer = fragments.nodes[i].videoContainer;
		const rect = fragments.nodes[i].rect;
		let mappedDistance;
		if (d.linked) {
			mappedDistance = mapTo(getDistance(mouse, d), 0, 800, 1, 0, true);
		} else {
			mappedDistance = mapTo(getDistance(mouse, d), 0, 500, 1, 0, true);
		}
		const preview = fragments.nodes[i].preview;

		const thresh = 0.5;
		if (d.hasVideo && !is_safari) {
			const video = preview;
			if (mappedDistance > thresh) {
				if (video.paused) {
					video.play();
				}
				video.volume = (mappedDistance < 0.9 ? mappedDistance * 0.5 : 0.5) * mouseTimer.val;
			} else if (mappedDistance <= thresh && !video.paused) {
				video.pause();
				video.volume = 0;
			}
		} else if (d.hasVideo && is_safari) {
			const video = preview;
			if (!video.paused) {
				video.volume = 0.5 * mouseTimer.val;
			}
		}

		if (d.linked) {
			fragments.nodes[i].h = fragments.sizes[States.Micro].nodeHeight * mouseTimer.val;
		} else {
			fragments.nodes[i].h =
				Math.floor(
					fragments.sizes[States.Macro].nodeHeight
						* (d.narrative * 0.125 + 0.5) * (1.0 - mappedDistance)
					+ ((fragments.sizes[States.Macro].scaleup
						* fragments.sizes[States.Macro].nodeHeight) * mappedDistance)
					) * mouseTimer.val;
		}
		fragments.nodes[i].w = fragments.nodes[i].h * 16.0 / 9.0; // all videos will have same ratio


		fragments.nodes[i].zIndex = 10 + Math.floor(mappedDistance * 90);
		const newPosX = moveFragment(mappedDistance, d.x, i, 0);
		const newPosY = moveFragment(mappedDistance, d.y, i, 1);

		fragments.nodes[i].x = Math.max(fragments.sizes[States.Macro].nodeWidth * 3,
			Math.min(width - fragments.sizes[States.Macro].nodeWidth, newPosX));
		fragments.nodes[i].y = Math.max(fragments.sizes[States.Macro].nodeHeight * 2,
			Math.min(height - fragments.sizes[States.Macro].nodeHeight, newPosY));

		const rectStyle = rect.style;
		rectStyle.width = `${fragments.nodes[i].w}px`;
		rectStyle.height = `${fragments.nodes[i].h}px`;
		rectStyle.x = `${d.w * -0.5}px`;
		rectStyle.y = `${d.h * -0.5}px`;

		preview.style.opacity = (mappedDistance * 0.8 + 0.2) * mouseTimer.val;

		videoContainer.style.zIndex = fragments.nodes[i].zIndex;
		videoContainer.style.width = `${fragments.nodes[i].w}px`;
		videoContainer.style.height = `${fragments.nodes[i].h}px`;
		videoContainer.style.left = `${Math.floor(fragments.nodes[i].x + fragments.nodes[i].w * -0.5)}px`;
		videoContainer.style.top = `${Math.floor(fragments.nodes[i].y + fragments.nodes[i].h * -0.5)}px`;

		if (fragments.nodes[i].textElement.style.opacity > 0.99) {
			fragments.nodes[i].textElement.style.opacity = 0.99;
			fragments.nodes[i].textElement.style.transition = 'opacity 0.5s';
			fragments.nodes[i].textElement.style.opacity = 0.0;
		}

		return `translate( ${d.x},${d.y} )`;
	});

	refreshLinks();

	svg.selectAll('.node').sort((a, b) => { // select the parent and sort the path's
		if (a.zIndex > b.zIndex) {
			return 1; // a is not the hovered element, send 'a' to the back
		}

		return -1; // a is the hovered element, bring 'a' to the front
	});

	if (getUrlParameter('share') !== undefined
		&& getUrlParameter('share').split(',').map(Number).length > 0
		&& shared === false) {
		shared = true;
		setTimeout(() => {
			const sharedPath = getUrlParameter('share').split(',').map(Number);
			runSharedPath(sharedPath);
		}, 2000);
	}
});

function loop() {
	console.log('resuming d3 force');
	d3.timer(() => {
		force.resume();
	});
}

let tick = () => {};

function setForce(state) {
	switch (state) {
	case States.Over:
		{
			force.on('tick', ticks[state]);
			force.on('end', loop);

			// force.pause();
			break;
		}

	case States.Macro:
		{
			force.on('tick', ticks[state]);
			force.on('end', loop);

			// force.pause();
			// force.resume();
			break;
		}

	case States.Micro:
		{
			force.on('tick', ticks[state]);
			force.on('end', loop);
			// force.pause();
			break;
		}

	default:
		{
			//
		}
	}
}

function updateLinks() {
	link = link.data(force.links(), (d) => {
		return `${d.source.id}-${d.target.id}`;
	});
	link.enter()
		.insert('line', '.node')
		.attr('class', 'link')
		.attr('data-source', (d) => {
			return d.source.id;
		})
		.attr('data-target', (d) => {
			return d.target.id;
		})
		.style('stroke', (d) => {
			return 'white'; // d.source.color; //
		}) // linkColor; }); //
		.style('stroke-width', 2);
		// .style('transition', 'stroke 2s');
	link.exit().remove();
}

const createLink = (src, trg) => {
	const _link = {
		source: src,
		target: trg,
	};
	fragments.links.push(_link);
	updateLinks();
	force.start();
};

const getSimilarTagAmont = (d, other) => {
	let similars = 0;
	for (let i = 0; i < d.tags.length; i++) {
		for (let j = 0; j < other.tags.length; j++) {
			if (d.tags[i] === other.tags[j]) {
				similars++;
			}
		}
	}

	return similars;
};

const getRecommendations = (d) => {
	let out = fragments.nodes.map((elem) => {
		return { node: elem, strength: getSimilarTagAmont(d, elem) };
	});
	out = out.sort((a, b) => {
		return b.strength - a.strength;
	});
	out = out.filter((elem) => {
		return elem.node.index !== d.index && !elem.node.linked;
	});
	return out;
};

window.offlineVideoEnded = () => {
	console.log('OFFLINE VIDEO ENDED');
	$(audio).stop().animate({ volume: 1.0 }, audioFadeInTime);
	if (autoplay) {
		const node = $('.offlineVideo').data('node');
		const r = getRecommendatedNode(node);
		if (r !== -1) {
			console.log('OFFLINE VIDEO – AUTOPLAY – NEXT VID!');
			dragging = -1;
			clickFragment(r);
		}
	} else {
		console.log('OFFLINE VIDEO – AUTOPLAY – LOOP!');
		$('.offlineVideo').get(0).play();
	}
}

const getOfflineVideoString = (fragment) => {
	return `<video class="offlineVideo" id="${fragment.id}_yt" controls autoplay onended="offlineVideoEnded();"><source id="${fragment.id}_yt_mp4_src" src="assets/videos/offMp4/${fragment.previewUrl}.mp4" type="video/mp4"></video>`;
};

const focusTransition = (vWidth, vHeight) => {
	deactivateAction = true;
	const recommendations = getRecommendations(youtube);
	const highestRec = recommendations[0].strength;

	// let totalHighestRec = 0;
	// fragments.nodes.forEach((elem, index) => {
	// 	totalHighestRec = Math.max(getRecommendations(elem)[0].strength, totalHighestRec);
	// });

	node.each((d, i) => {
		if (d.focus === false) {
			d3.select(d.textElement).transition().style('opacity', 0);
		}

		fragments.nodes[d.index].sx = d.x;
		fragments.nodes[d.index].sy = d.y;

		fragments.nodes[d.index].sw = fragments.nodes[d.index].w;
		fragments.nodes[d.index].sh = fragments.nodes[d.index].h;
		fragments.nodes[d.index].fixed = true;

		const videoContainer = fragments.nodes[d.index].videoContainer;
		const preview = fragments.nodes[d.index].preview;

		fragments.nodes[d.index].so = parseFloat(videoContainer.style.opacity);

		if (d.hasVideo) {
			const video = preview;
			if (!video.paused) {
				video.pause();
				video.volume = 0;
			}
		}
	});

	const step = ((2 * Math.PI) / 54.0) * 4;
	let angle = 0;
	recommendations.forEach((d, i) => {
		fragments.nodes[d.node.index].fw = Math.floor(fragments.sizes[States.Macro].nodeWidth * 0.5 + d.strength * fragments.sizes[States.Macro].nodeWidth  * 1.0);
		fragments.nodes[d.node.index].fh = Math.floor(fragments.sizes[States.Macro].nodeHeight * 0.5 + d.strength * fragments.sizes[States.Macro].nodeHeight * 1.0);

		const radius = 4 * (i + (highestRec - d.strength));
		fragments.nodes[d.node.index].fx = Math.round((width / 2) + ((radius + vWidth * 0.8) * Math.cos(angle)));
		fragments.nodes[d.node.index].fy = Math.round((height / 2) + ((radius + vHeight * 0.6) * Math.sin(angle)));
		angle += step;

		fragments.nodes[d.node.index].fo = mapTo(d.strength, 0, highestRec, 0.1, 1.25);
		fragments.nodes[d.node.index].fo = Math.max(Math.min(Math.pow(fragments.nodes[d.node.index].fo, 3), 1.0), 0.1);
	});
	d3.transition()
			.duration(1000)
			.ease('linear')
			.tween('foo', () => {
				// const i = d3.interpolate(params.current.linkDistance, params.next.linkDistance);
				return (t) => {
					node.attr('transform', (d, i) => {
						const finalTranslate = {};
						const finalScale = {};
						const videoContainer = fragments.nodes[i].videoContainer;
						const preview = fragments.nodes[i].preview;
						const mappedDistance = mapTo(getDistance(mouse, d), 0, 400, 1, 0, true);
						if (d.focus === true) {
							finalTranslate.x = width * 0.5;
							finalTranslate.y = height * 0.5;
							finalScale.w = vWidth;
							finalScale.h = vHeight;
							preview.style.opacity = `${0.8 + t * 0.2}`;
							videoContainer.style.zIndex = 101;
						} else if (d.linked === true) {
							finalTranslate.x = d.x - 10;
							finalTranslate.y = d.y - 50;
							finalScale.w = fragments.sizes[States.Macro].nodeWidth;
							finalScale.h = fragments.sizes[States.Macro].nodeHeight;
						} else {
							finalTranslate.x = d.fx; //(i * 9665.12) % width;
							finalTranslate.y = d.fy; //(i * 76876.876) % height;
							finalScale.w = d.fw;
							finalScale.h = Math.floor(
								d.fh * (1.0 - mappedDistance)
								+ ((d.fh * 1.1 + (fragments.sizes[States.Macro].nodeHeight * fragments.sizes[States.Macro].scaleup * 0.3)) * mappedDistance)
							);
							videoContainer.style.opacity = `${d.so * (1.0 - t) + t * d.fo}`;
						}

						fragments.nodes[i].x = finalTranslate.x * t + fragments.nodes[i].sx * (1 - t);
						fragments.nodes[i].y = finalTranslate.y * t + fragments.nodes[i].sy * (1 - t);

						fragments.nodes[i].h = finalScale.h * t + fragments.nodes[i].sh * (1 - t);
						fragments.nodes[i].w = fragments.nodes[i].h * 16.0 / 9.0;

						// $(`#${d.id}_rect`).attr('width', fragments.nodes[i].w);
						// $(`#${d.id}_rect`).attr('height', fragments.nodes[i].h);
						// $(`#${d.id}_rect`).attr('transform', `translate(${fragments.nodes[i].w * -0.5},${fragments.nodes[i].h * -0.5})`);

						const rect = fragments.nodes[i].rect;
						rect.setAttribute('width', `${fragments.nodes[i].w}px`);
						rect.setAttribute('height', `${fragments.nodes[i].h}px`);
						rect.setAttribute('x', `${d.w * -0.5}px`);
						rect.setAttribute('y', `${d.h * -0.5}px`);

						// const rectStyle = rect.style;
						// rectStyle.width = `${fragments.nodes[i].w}px`;
						// rectStyle.height = `${fragments.nodes[i].h}px`;
						// rectStyle.x = `${d.w * -0.5}px`;
						// rectStyle.y = `${d.h * -0.5}px`;

						// $(`#${d.id}_preview`)[0].style.height = fragments.nodes[i].h + 'px';

						videoContainer.style.left = `${fragments.nodes[i].x + fragments.nodes[i].w * -0.5}px`;
						videoContainer.style.top = `${fragments.nodes[i].y + fragments.nodes[i].h * -0.5}px`;
						videoContainer.style.width = `${fragments.nodes[i].w}px`;
						videoContainer.style.height = `${fragments.nodes[i].h}px`;

						return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
					});
					refreshLinks();
				};
			})
			.each('end', () => {
				node.attr('transform', (d, i) => {
					$(`#${d.id}_video_container`).css('z-index', 201);
					fragments.nodes[i].px = fragments.nodes[i].x;
					fragments.nodes[i].py = fragments.nodes[i].y;
					fragments.nodes[i].sx = fragments.nodes[i].x;
					fragments.nodes[i].sy = fragments.nodes[i].y;
					if (d.focus !== true) {
						fragments.nodes[i].fixed = false;
					} else {
						d3.select(d.textElement).transition().style('opacity', 1);
					}
					deactivateAction = false;

					return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
				});
				refreshLinks();
			});
};

const unFocusTransition = () => {
	deactivateAction = true;
	node.each((d, i) => {
		fragments.nodes[i].sx = d.x;
		fragments.nodes[i].sy = d.y;

		fragments.nodes[i].sw = fragments.nodes[i].w;
		fragments.nodes[i].sh = fragments.nodes[i].h;
		fragments.nodes[i].fixed = true;
	});
	d3.transition()
			.duration(1000)
			.ease('linear')
			.tween('foo', () => {
				// const i = d3.interpolate(params.current.linkDistance, params.next.linkDistance);
				return (t) => {
					node.attr('transform', (d, i) => {
						const finalTranslate = {};
						const finalScale = {};
						finalTranslate.x = d.x;
						finalTranslate.y = d.y;

						finalScale.w = d.w;
						finalScale.h = d.h;

						fragments.nodes[i].x = finalTranslate.x * t + fragments.nodes[i].sx * (1 - t);
						fragments.nodes[i].y = finalTranslate.y * t + fragments.nodes[i].sy * (1 - t);

						fragments.nodes[i].w = finalScale.w * t + fragments.nodes[i].sw * (1 - t);
						fragments.nodes[i].h = finalScale.h * t + fragments.nodes[i].sh * (1 - t);

						$(`#${d.id}_rect`).attr('width', fragments.nodes[i].w);
						$(`#${d.id}_rect`).attr('height', fragments.nodes[i].h);

						// $(`#${d.id}_rect`).attr('transform', `translate(${fragments.nodes[i].w * -0.5},${fragments.nodes[i].h * -0.5})`);

						// $(`#${d.id}_preview`)[0].style.height = fragments.nodes[i].h + 'px';

						$(`#${d.id}_video_container`)[0].style.left = `${fragments.nodes[i].x + fragments.nodes[i].w * -0.5}px`;
						$(`#${d.id}_video_container`)[0].style.top = `${fragments.nodes[i].y + fragments.nodes[i].h * -0.5}px`;
						$(`#${d.id}_video_container`)[0].style.width = `${fragments.nodes[i].w}px`;
						$(`#${d.id}_video_container`)[0].style.height = `${fragments.nodes[i].h}px`;

						return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
					});
					refreshLinks();
				};
			})
			.each('end', () => {
				node.attr('transform', (d, i) => {
					$(`#${d.id}_video_container`).css('z-index', 201);
					fragments.nodes[i].px = fragments.nodes[i].x;
					fragments.nodes[i].py = fragments.nodes[i].y;
					fragments.nodes[i].sx = fragments.nodes[i].x;
					fragments.nodes[i].sy = fragments.nodes[i].y;
					$('#' + d.textElement.id).css('transition', 'opacity 0s');
					$('#' + d.textElement.id).css('opacity', 0);

					fragments.nodes[i].fixed = false;
					return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
				});
				refreshLinks();
				deactivateAction = false;
			});
};

const unfocus = () => {
	if (focus !== false) {

		node.each((d, i) => {
			d.fixed = false;
		});

		youtube.textElement.style.opacity = 0;
		youtube.focus = false;
		youtube.fixed = false;
		youtube.standardCharge = params.current.standardCharge;
		$(`#${youtube.id}_yt`).remove();

		youtube.preview.style.opacity = 1.0;

		focus = false;
		if (!offline) {
			player.stopVideo();
		}

		let playerElem = document.getElementById('ytplayer');
		$(playerElem).hide();
		unFocusTransition();
		youtube = false;
	}

	$(audio).stop().animate({ volume: 1.0 }, audioFadeInTime);
	node.each((d, i) => {
		d.standardCharge = params.current.standardCharge;
	});
	force.charge(params.current.standardCharge);
	force.friction(params.current.friction);
	force.start();
};

const focusOn = (d, vWidth, vHeight, millis=1000) => {
	// const videoContainer = fragments.nodes[d.index].videoContainer;
	// videoContainer.style.transition = '1s';
	// videoContainer.style.width = `${(vWidth)}px`;
	// videoContainer.style.height = `${(vHeight)}px`;
	// videoContainer.style.left = `${(width * 0.5) - (vWidth * 0.5)}px`;
	// videoContainer.style.top = `${(height * 0.5) - (vHeight * 0.5)}px`;
	
	$(audio).stop().animate({ volume: Math.min(1.0, d.sound.basisAmp) }, audioFadeInTime);

	fragments.nodes[d.index].focus = true;
	youtube = d;

	if (params.current.state === States.Macro) {
		fragments.nodes[d.index].standardCharge = params.current.maxCharge;
		focusTransition(vWidth, vHeight);
	}

	setTimeout(() => {
		setTimeout(() => {
			fragments.nodes[d.index].preview.style.opacity = 0.0;
		}, 500);
		let playerElem = document.getElementById('ytplayer');
		$(playerElem).show();
		if (params.current.state === States.Micro) {
			playerElem.style.width = '100%';
			playerElem.style.height = '100%';
			playerElem.style.left = '0px';
			playerElem.style.top = '0px';
			playerElem.style.zIndex = 4;
			playerElem.style.borderColor = 'initial';
		} else {
			playerElem.style.width = `${(vWidth)}px`;
			playerElem.style.height = `${(vHeight)}px`;
			playerElem.style.left = `${Math.floor((width * 0.5) - (vWidth * 0.5))}px`;
			playerElem.style.top = `${Math.floor((height * 0.5) - (vHeight * 0.5))}px`;
			playerElem.style.borderColor = d.color;
			playerElem.style.zIndex = 201;
		}
	}, millis);

	// force.friction((d) => {
	// 	return d.linked ? params.current.friction : 0.0;
	// });
	focus = true;
};

const clickFragment = (d) => {
	if (!deactivateAction) {
		if (dragging < 2 && d.focus !== true) {
			if (params.current.state !== States.Micro || $('#sub').is(':visible')) {
				$('#utils #navigation .over').slideDown(1000);
			}
			$('#utils #navigation .over').addClass('rowActive');
			if (focus) {
				youtube.focus = false;
				youtube.fixed = false;
				youtube.standardCharge = params.current.standardCharge;
				$(youtube.preview).show();
				if (!offline) {
					player.stopVideo();
				}

				let playerElem = document.getElementById('ytplayer');
				$(playerElem).hide();
			} else {
				unfocus();
			}

			const vWidth = (fragments.sizes[States.Macro].nodeWidth + fragments.sizes[States.Macro].nodeWidth * fragments.sizes[States.Macro].scaleup) * 1.5;
			const vHeight = (fragments.sizes[States.Macro].nodeHeight + fragments.sizes[States.Macro].nodeHeight * fragments.sizes[States.Macro].scaleup) * 1.5;

			if (!offline) {
				const vidDiv = document.createElement('div');
				vidDiv.id = `${d.id}_yt`;
				const youtubeID = d.youtube; //'dQw4w9WgXcQ';
				player.loadVideoById({ videoId: youtubeID,
					suggestedQuality: 'large',
				});
			} else {
				$('#ytplayer').html(getOfflineVideoString(d));
				$('#ytplayer').find('.offlineVideo').data('node', d);
			}

			if (params.current.state === States.Micro) {
				focusOn(d, vWidth, vHeight, 0);
			} else {
				focusOn(d, vWidth, vHeight);
			}

			d.linked = true;
			if (lastNode !== -1) {
				createLink(lastNode, d);
			}

			lastNode = d;
			force.charge((dd) => {
				return dd.focus === true ? d.standardCharge : params.current.standardCharge * 0.75;
			});
			force.start();
		}
	}
};

const mouseoverFragment = (d) => {
	overTimer = setTimeout(() => {
		if (is_safari && d.hasVideo) {
			const video = d.preview;
			video.volume = 0.5;
			video.play();
		}
	}, 100);
};

const mouseoutFragment = (d) => {
	clearTimeout(overTimer);
	if (is_safari && d.hasVideo && !d.paused) {
		const video = d.preview;
		video.pause();
	}
};

const toggleScrollBar = (enable) => {
	if (enable) {
		if (maxScroll < 0) {
			document.getElementById('scrollBar').style.display = 'block';
			document.getElementById('scrollBar').style.opacity = 1.0;
			updateScrollBar();
			setTimeout(() => {
				updateScrollBar();
			}, 500);
		}
	} else {
		document.getElementById('scrollBar').style.opacity = 0.0;
		setTimeout(() => {
			document.getElementById('scrollBar').style.display = 'none';
		}, 500);
	}
};

const transitions = [

	// State.* -> State.Over
	(millis) => {
		if (focus) { 
			unfocus();
		}

		node.each((d, i) => {
			fragments.nodes[i].sx = d.x;
			fragments.nodes[i].sy = d.y;
			d3.select(d.textElement).style('transition', '');
			d3.select(d.textElement).style('opacity', 0);
			fragments.nodes[i].sw = fragments.nodes[i].w;
			fragments.nodes[i].sh = fragments.nodes[i].h;
			fragments.nodes[i].fixed = true;
			$(`#${d.id}_rect`).css('opacity', 0.0);
		});
		$('.video').each((i, v) => {
			v.pause();
		});

		scroll = 0;

		const margin = {
			x: 520,
			y: 140,
		};
		const padding = {
			x: 60,
			y: 260,
		};
		const f1 = width / (fragments.sizes[States.Over].nodeWidth + padding.x);
		const f2 = Math.floor(margin.x / (fragments.sizes[States.Over].nodeWidth));
		const gridNX = Math.floor(f1 - f2);

		d3.transition()
			.duration(millis)
			.ease('linear')
			.tween('foo', () => {
				// const i = d3.interpolate(params.current.linkDistance, params.next.linkDistance);
				return (t) => {
					let gridIndex = 0;
					node.attr('transform', (d, i) => {
						const finalTranslate = {
							x: Math.random() * 50 + width * 0.5,
							y: Math.random() * 50 + height * 0.5,
						};
						if (d.linked) {
							finalTranslate.x = ((fragments.sizes[States.Over].nodeWidth + padding.x) * (gridIndex % gridNX)) + margin.x;
							finalTranslate.y = (Math.floor(gridIndex / gridNX) * (fragments.sizes[States.Over].nodeHeight + padding.y)) + margin.y;

							gridIndex++;
							$(`#${d.id}_preview`).css('opacity', t * 0.6 + 0.4);
							fragments.nodes[i].w = fragments.sizes[States.Over].nodeWidth * t + fragments.nodes[i].sw * (1 - t);
							fragments.nodes[i].h = fragments.sizes[States.Over].nodeHeight * t + fragments.nodes[i].sh * (1 - t);
							fragments.nodes[i].x = finalTranslate.x * t + fragments.nodes[i].sx * (1 - t);
							fragments.nodes[i].y = finalTranslate.y * t + fragments.nodes[i].sy * (1 - t);
						} else {
							// d.sx = finalTranslate.x;
							// d.sy = finalTranslate.y;
							const tt = Math.min(1.0, t * 2);
							fragments.nodes[i].x = finalTranslate.x * tt + fragments.nodes[i].sx * (1 - tt);
							fragments.nodes[i].y = finalTranslate.y * tt + fragments.nodes[i].sy * (1 - tt);
							$(`#${d.id}_preview`).css('opacity', (1.0 - (tt * tt)) * 0.8);
						}

						const videoContainer = fragments.nodes[i].videoContainer;
						const preview = fragments.nodes[i].preview;
						if (d.hasVideo) {
							const video = preview;
							video.pause();
							video.volume = 0;
						}

						$(`#${d.id}_rect`).attr('width', fragments.nodes[i].w);
						$(`#${d.id}_rect`).attr('height', fragments.nodes[i].h);
						$(`#${d.id}_rect`).attr('transform', `translate(${fragments.nodes[i].w * -0.5},${fragments.nodes[i].h * -0.5})`);

						// $(`#${d.id}_preview`)[0].style.height = fragments.nodes[i].h + 'px';

						$(`#${d.id}_video_container`)[0].style.left = `${fragments.nodes[i].x + fragments.nodes[i].w * -0.5}px`;
						$(`#${d.id}_video_container`)[0].style.top = `${fragments.nodes[i].y + fragments.nodes[i].h * -0.5}px`;
						$(`#${d.id}_video_container`)[0].style.width = `${fragments.nodes[i].w}px`;
						$(`#${d.id}_video_container`)[0].style.height = `${fragments.nodes[i].h}px`;

						return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
					});
					refreshLinks();
				};
			})
			.each('end', () => {
				params.last = params.current;
				params.current = params.next;
				params.next = -1;

				let gridIndex = 0;
				node.attr('transform', (d, i) => {
					const videoContainer = fragments.nodes[i].videoContainer;
					videoContainer.style.zIndex = 201;
					if (d.linked) {
						maxScroll = height - (d.y + fragments.sizes[States.Over].nodeHeight + padding.y);

						fragments.nodes[i].x = ((fragments.sizes[States.Over].nodeWidth + padding.x) * (Number(gridIndex) % gridNX)) + margin.x;
						fragments.nodes[i].y = (Math.floor(Number(gridIndex) / gridNX) * (fragments.sizes[States.Over].nodeHeight + padding.y)) + margin.y;

						d.textElement.style.opacity = 1.0;

						gridIndex++;
					} else {
						d.fixed = true;
					}

					const preview = fragments.nodes[i].preview;
					if (d.hasVideo) {
						const video = preview;
						video.pause();
						video.volume = 0;
					}

					fragments.nodes[i].px = d.x;
					fragments.nodes[i].py = d.y;
					return `translate( ${fragments.nodes[i].x}, ${fragments.nodes[i].y} )`;
				});
				toggleScrollBar(true);
				refreshLinks();
				setForce(params.current.state);
				$(audio).stop().animate({ volume: 0.0 }, audioFadeOutTime);
			});


	},

	// State.* -> State.Macro
	(millis) => {
		// unfocus();
		toggleScrollBar(false);

		const vWidth = (fragments.sizes[States.Macro].nodeWidth +
			fragments.sizes[States.Macro].nodeWidth * fragments.sizes[States.Macro].scaleup) * 1.5;
		const vHeight = (fragments.sizes[States.Macro].nodeHeight +
			fragments.sizes[States.Macro].nodeHeight * fragments.sizes[States.Macro].scaleup) * 1.5;

		node.each((d, i) => {
			// d.sx = d.x;
			// d.sy = d.y;
			fragments.nodes[i].sw = $(`#${d.id}_video_container`).width();
			fragments.nodes[i].sh = $(`#${d.id}_video_container`).height();
			fragments.nodes[i].fixed = false;

			// $(`#${d.id}_rect`).css('opacity', 0.0);
			$('.nodeElem').css('opacity', 1.0);
			d.rect.style.opacity = 0;
			d.textElement.style.transition = '';
			d.textElement.style.opacity = 0;
			d.preview.style.borderWidth = '1px';

			// if (d.focus) {
			// 	$('#ytplayer').animate({
			// 		width: `${(vWidth)}px`,
			// 		height: `${(vHeight)}px`,
			// 		left: `${Math.floor((width * 0.5) - (vWidth * 0.5))}px`,
			// 		top: `${Math.floor((height * 0.5) - (vHeight * 0.5))}px`,
			// 		zIndex: 201,
			// 	}, 1000);

			// 	$('#ytplayer').css('borderColor', d.color);
			// }
		});
		
		params.last = params.current;
		params.current = params.next;
		params.next = -1;

		setForce(States.Macro);
		if (params.last.state === States.Micro) {
			unfocus();
		} else {
			unfocus();
			if (focus) {
				// console.log('focus transition');
				// node.each((d, i) => {
				// 	d.standardCharge = params.current.standardCharge;
				// });
				// force.charge(params.current.standardCharge);
				// force.friction(params.current.friction);
				// force.start();
			} else {
				// unfocus();
			}

			node.each((d, i) => {
				d.textElement.style.transition = '';
				d.textElement.style.opacity = 0;
			});
			d3.transition()
				.duration(millis)
				.ease('linear')
				.tween('foo', () => {
					return (t) => {
						// node.each((d, i) => {
						// 	const videoContainer = fragments.nodes[i].videoContainer;
						// 	fragments.nodes[i].h = fragments.sizes[params.current.state].nodeHeight * t + fragments.nodes[i].sh * (1 - t);
						// 	fragments.nodes[i].w = d.h * 16.0 / 9.0;
						// 	videoContainer.style.width = `${fragments.nodes[i].w}px`;
						// 	videoContainer.style.height = `${fragments.nodes[i].h}px`;
						// });
						// refreshLinks();
					};
				})
				.each('end', () => {
					// enable dragging
					$('.rect').css('pointer-events', 'all');
					refreshLinks();
					console.log(`ending to Macro with ${params.current.state}`);
				});
		}
	},

	// State.* -> State.Micro
	(millis) => {
		toggleScrollBar(false);
		node.each((d, i) => {
			// d.sx = d.x;
			// d.sy = d.y;
			fragments.nodes[i].sw = fragments.nodes[i].w;
			fragments.nodes[i].sh = fragments.nodes[i].h;
			fragments.nodes[i].fixed = false;

			// $(`#${d.id}_rect`).css('opacity', 0.0);
			$('.nodeElem').css('opacity', 1.0);
			fragments.nodes[i].rect.style.opacity = 0;
			fragments.nodes[i].textElement.style.transition = '';
			fragments.nodes[i].textElement.style.opacity = 0;
			fragments.nodes[i].preview.style.borderWidth = '1px';
		});
		params.last = params.current;
		params.current = params.next;
		params.next = -1;
		setForce(States.Micro);
		if (focus) {
			console.log('focus transition');
			$('#ytplayer').animate({
				width: '100%',
				height: '100%',
				left: '0px',
				top: '0px',
				zIndex: 4,
			}, 1000);

			$('#ytplayer').css('borderColor', 'initial');

			node.each((d) => {
				d.standardCharge = params.current.standardCharge;
			});
			force.charge(params.current.standardCharge);
			force.friction(params.current.friction);
			force.start();
			// let playerElem = document.getElementById('ytplayer');

			// playerElem.style.width = '100%';
			// playerElem.style.height = '100%';
			// playerElem.style.left = '0px';
			// playerElem.style.top = '0px';
			// playerElem.style.zIndex = 0;
			// playerElem.style.borderColor = 'transparent';
		} else {
			console.log('unfocus transition');
			// code to play video by default?
			// unfocus();
		}
		node.each((d, i) => {
			fragments.nodes[i].textElement.style.transition = '';
			fragments.nodes[i].textElement.style.opacity = 0;
		});
		d3.transition()
			.duration(millis)
			.ease('linear')
				.tween('foo', () => {
					return (t) => {
						// node.each((d, i) => {
						// 	const videoContainer = fragments.nodes[i].videoContainer;
						// 	fragments.nodes[i].h = fragments.sizes[params.current.state].nodeHeight * t + fragments.nodes[i].sh * (1 - t);
						// 	fragments.nodes[i].w = d.h * 16.0 / 9.0;
						// 	videoContainer.style.width = `${fragments.nodes[i].w}px`;
						// 	videoContainer.style.height = `${fragments.nodes[i].h}px`;
						// });
						// refreshLinks();
					};
				})
				.each('end', () => {
					// enable dragging
					$('.rect').css('pointer-events', 'all');
					refreshLinks();
					if (!focus) {
						if (lastNode === -1) {
							clickFragment(fragments.nodes[Math.floor(Math.random() * (fragments.nodes.length - 1))]);
						} else {
							clickFragment(lastNode);
						}
					}
					console.log(`ending to Micro with ${params.current.state}`);
				});
	},
];

const setForceParams = (forceParams, millis = 0) => {
	if (params.current !== -1 && params.last !== 'transition') {
		params.next = forceParams;
		params.last = 'transition';
		transitions[params.next.state](millis);
	} else if (params.last === 'transition') {
		console.log('new transition ignored, since old transition still in progress');
	} else {
		params.current = forceParams;
		force.linkDistance(params.current.linkDistance);
		force.friction(params.current.friction);
		force.gravity(params.current.gravity);
		force.charge(params.current.standardCharge);
		setForce(params.current.state);
		force.start();
	}
};

const setup = () => {
	width = $(window).width();
	height = $(window).height();
	link = svg.selectAll('.link')
		.data(fragments.links)
		.enter().append('g')
		.attr('class', 'link');

	link.append('line')
		.style('stroke-width', (d) => {
			return `${(d.bond * 2 - 1) * 2}px`;
		});

	link.filter((d) => {
		return d.bond > 1;
	}).append('line')
	.attr('class', 'separator');
	node = svg.selectAll('.node')
		.data(fragments.nodes)
		.enter().append('g')
		.attr('class', (d) => { return `node ${d.id}`; })
		.on('mousedown', () => {
			dragging = 0; hitNode = 1;
		})
		.on('mousemove', () => { if (dragging >= 0) { dragging += 1; } })
		.on('mouseup', clickFragment)
		.on('mouseover', mouseoverFragment)
		.on('mouseout', mouseoutFragment)
		.call(force.drag);

	$('.video').css('border-color', 'green');

	node.each((d) => {
		d.videoContainer.style.borderColor = d.color;
		$(`#${d.id}_text .category`).css('color', d.color);
		$(`#${d.id}_collectedText p`).css('color', d.color);
	});

	// rect necessary for dragging node etc
	node.append('rect')
		.attr('class', 'rect')
		.attr('id', (d) => {
			return `${d.id}_rect`;
		})
		.style('fill', 'rgba(0,0,0,0)')

		// debug borders
		// .style('stroke', 'rgba(255,0,0,255)')
		// .style('stroke-width', '20px')
		.style('opacity', (d) => {
			return 1.0;
		})
		.attr('width', (d) => {
			return fragments.sizes[params.current.state].nodeWidth;
		})
		.attr('height', (d) => {
			return fragments.sizes[params.current.state].nodeHeight;
		})
		.attr('x', (d) => {
			return fragments.sizes[params.current.state].nodeWidth * -0.5;
		})
		.attr('y', (d) => {
			return fragments.sizes[params.current.state].nodeHeight * -0.5;
		})
		.each(function (d) {
			d.rect = d3.select(this).node();
		});

	node.each((d, i) => {
		fragments.nodes[i].x = Math.random() * 50 + width * 0.5;
		fragments.nodes[i].y = Math.random() * 50 + height * 0.5;
	});

	setupScrollBar();
};

const start = () => {
	force
		.nodes(fragments.nodes)
		.links(fragments.links)
		.on('tick', tick)
		.charge(-400)
		.start();
	unfocus();
	// javascript:(function(){var script=document.createElement('script');script.onload=function(){var stats=new Stats();document.body.appendChild(stats.dom);requestAnimationFrame(function loop(){stats.update();requestAnimationFrame(loop)});};script.src='//rawgit.com/mrdoob/stats.js/master/build/stats.min.js';document.head.appendChild(script);})();
};

const runSharedPath = (path) => {
	if (path.length > 0) {
		clickFragment(fragments.nodes[path[0]]);
		path.splice(0, 1);
		setTimeout(() => {
			runSharedPath(path);
		}, 5000);
	}
};

const getRecommendatedNode = (d) => {
	let out = -1;
	if (d === -1) {
		fragments.nodes.forEach((dd) => {
			if (out === -1) {
				out = dd;
			} else if (dd.narrative > out.narrative) {
				out = dd;
			}
		});
	} else {
		const recommendations = getRecommendations(d);
		if (recommendations.length > 0) {
			out = recommendations[0].node;
		}
	}
	return out;
};

const setupYTPlayer = shareIDs => {
	let hasControls = 1;
	if (params.current.state === States.Micro) {
		hasControls = 0;
	}
	player = new YT.Player('ytplayer', {
		height: '100%',
		width: '100%',
		videoId: '',
		playerVars: { autoplay: 0, controls: hasControls, showinfo: 0, loop: 0, rel: 0 },
		origin: 'https://www.youtube.com',
		events: {
			onStateChange: (event) => {
				if (event.data === 0) {
					$(audio).stop().animate({ volume: 1.0 }, audioFadeInTime);
					if (autoplay) {
						const r = getRecommendatedNode(youtube);
						if (r !== -1){
							clickFragment(r);
						}
					}
				}
			},
			onReady: () => {
				if (shareIDs.length > 0) {
					runSharedPath(shareIDs);
				}
			},
		},
	});
};

// let fragmentPopper;

export class ForceLayout {
	constructor(shareIds, _is_safari) {
		this.shareIds = shareIds;
		is_safari = _is_safari;
	}

	setViewport(_width, _height) {
		width = _width;
		height = _height;
		svg
			.attr('width', width)
			.attr('height', height);

		force
			.size([width, height])
			.start();

		createScrollParams();
	}

	setup(fragmentParser, _fragmentSizes) {
		fragments.nodes = fragmentParser.nodes;
		fragments.links = [];
		fragments.sizes = _fragmentSizes;
		setup();

		$('#share').on('click', () => {
			// override share functionality w/ refresh for offline use
			// if (offline) window.location = window.location;

			if (fragments.links.length >= 2) {
				let shareThis = [];
				for (let i = 0; i < fragments.links.length; i++) {
					// if(shareThis.indexOf(fragments.links[i]) >=0 ){
					shareThis.push(fragments.links[i].source.index);

					// }
				}

				shareThis.push(fragments.links[fragments.links.length - 1].target.index);

				const http = location.protocol;
				const slashes = http.concat('//');
				const host = slashes.concat(window.location.hostname);
				let url = host + '?share=' + shareThis.join(',');
				url = url.replace('localhost', 'themodularbody.com');

				window.open('http://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(url)+'&title=The Modular Body');
			}
		});
	}

	start() {
		if (is_safari) {
			setupYTPlayer(this.getShareIDs());
		}
		start();
	}

	setDeactivateAction(_deactivateAction) {
		deactivateAction = _deactivateAction;
	}

	getNodes() {
		return node;
	}

	getLinks() {
		return link;
	}

	getShareIDs() {
		return this.shareIds;
	}

	setShareIDs(shareIds) {
		this.shareIds = shareIds;
	}

	setForce(forceParams, millis) {
		tick = ticks[forceParams.state];
		setForceParams(forceParams, millis);
		force.start();
	}

	setupYT() {
		if (!is_safari) {
			setupYTPlayer(this.getShareIDs());
		}
	}

	setOffline(_offline) {
		offline = _offline;
	}

	setAutoplay(_autoplay) {
		autoplay = _autoplay;
		if (autoplay && !focus && params.current.state !== States.Over) {
			const r = getRecommendatedNode(lastNode);
			if (r !== -1) {
				clickFragment(r);
			}
		}
	}

	unfocus() {
		if (focus) {
			unfocus();
		}
	}

	mousescroll(delta) {
		if (params.current.state === States.Over) {
			scroll += delta;
			if (scroll > 0) {
				scroll = 0;
			} if (scroll < maxScroll) {

				if (maxScroll < 0) {
					scroll = maxScroll;
				} else {
					scroll = 0;
				}
			}

			if (maxScroll < 0) {
				// 	$('scrollBar').show();
				updateScrollBar();
			}
		}
	}

	mousemove(event) {
		mouse.x = event.clientX;
		mouse.y = event.clientY;
		$(mouseTimer).stop();
		mouseTimer.val = Math.min(1.0, mouseTimer.val + 0.05);
		$(mouseTimer).delay(1000).animate({ val: 0.0 }, {
			duration: 2000,
		});
	}

	mouseup(event) {
		if (deactivateAction) return;

		if (params.current.state !== States.Over) {
			if (hitNode === 0) {
				unfocus();
			} else {
				hitNode = 0;
			}
		}
		dragging = -1;
	}

	reset() {
		lastNode = -1;
		force.links();
		fragments.links = [];
		node.each((d, i) => {
			d.fixed = false;
			d.linked = false;
		});
		unfocus();
	}
}
